import dayjs from "dayjs"

export const isDateDueIn90Days = (date: string) => {
  const currentDate = dayjs()

  const targetDate = dayjs(date)
  const differenceInDays = targetDate.diff(currentDate, "day")

  return differenceInDays <= 90
}
