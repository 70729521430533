// File of util fuctions that are used to return some components data
import type { BannerDeliveryPaymentsProps } from "~/components/Organisms/Cart/BannerDeliveryPayments.props"
import type { AdvantagesProps } from "~/components/Cart/Utils/Advantages.props"
import type { LoginProps } from "~/components/Cart/Utils/Login.props"
import type { WhereToStartProps } from "~/components/Cart/Utils/WhereToStart.props"
import type { BuyAgainProps } from "~/components/Organisms/Cart/BuyAgain.props"
import type { SavedForLaterProps } from "~/components/Cart/Views/SavedForLater.props"
import type { ButtonProps } from "~/components/Utils/Button.props"
import { Sku, FreeGiftPromotion, LineItem, SkuListItem } from "@commercelayer/sdk"
import type { CartProps } from "~/components/ProductTile/Overall/Cart.props"
import type { LineItem_ } from "~/composables/useOrder"
import type { WrapperImageProps } from "~/components/Utils/WrapperImage.props"
import { connect as connectImage } from "~/connectors/fragments/WrapperImage"
import { CartSavedProps } from "~/components/ProductTile/Overall/CartSaved.props"
import { isDateDueIn90Days } from "~/utils/checkExpiration"
import { getFormatDate } from "~/utils/date"
import { getFreeGiftQuantity } from "~/composables/useOrder"
import { ResultsProps } from "~/components/ProductTile/Overall/Results.props"
import { NotificationAlertProps } from "~/components/NotificationAlert.props"
import { cloneDeep } from "lodash"

export type FreeGiftData = {
	isFreeGift: boolean
	quantity: number
	splittedQuantity: number
}

export const useMapCart = () => {
  const { t } = useI18n()

  const mapBannersSection =
    {
      banners: [
        {
          iconType: "delivery",
          title: t("cart.deliveryBanners.banner3.title"),
          text: t("cart.deliveryBanners.banner3.text"),
        },
        {
          iconType: "box",
          title: t("cart.deliveryBanners.banner4.title"),
          text: t("cart.deliveryBanners.banner4.text"),
        },
      ],
    } satisfies BannerDeliveryPaymentsProps

  const mapWhereToStart = (ctas: ButtonProps[]) => ({
    title: t("cart.whereToStartSection.title"),
    subtitle: t("cart.whereToStartSection.subtitle"),
    ctas
  } satisfies WhereToStartProps)

  const mapAdvantages = {
    title: t("cart.advantagesSection.title"),
    advantageList: [
      {
        icon: "Price",
        text: t("cart.whereToStartSection.advantageList.discount"),
      },
      {
        icon: "Gift",
        text: t("cart.whereToStartSection.advantageList.gift"),
      },
      {
        icon: "ShoppingBag",
        text: t("cart.whereToStartSection.advantageList.personalDiscount"),
      },
      {
        icon: "Star",
        text: t("cart.whereToStartSection.advantageList.products"),
      },
    ],
  } satisfies AdvantagesProps

  const mapBuyAgain = (items: ResultsProps[] | null): BuyAgainProps => {
    return {
      results: {
        productList: items ?? []
      }
    }
  }

  const mapCartProduct = (
    item: LineItem_<Sku>
	): Omit<CartProps, keyof FreeGiftData> => {
    const appliedDiscounts = item.discount_breakdown ? Object.values(item.discount_breakdown).map(discountCode => {
      return discountCode.cents < -1 ? discountCode.coupon_code : null
    }).filter(codes => codes) : []

    return {
		line_item_id: item.id,
    productImage: connectImage(
      item?.metadata?.product_image
    ) as WrapperImageProps,
    productCode: item.sku_code!,
    title: item?.name ?? "",
    path: item?.metadata?.slug,
    oldPrice: item?.item?.prices![0]?.compare_at_amount_float,
    currentPrice: item.unit_amount_float!,
    inStock: (item as any)?.item?.inventory?.available,
    expirationDate: item?.metadata?.expirationDate && isDateDueIn90Days(item?.metadata?.expirationDate) ? getFormatDate(item?.metadata?.expirationDate) : '',
    // TODO: cart - handle price per unit
    pricePerUnit: item?.item?.prices![0].amount_float,
    // TODO: cart - handle price per unit of mesure
    unitType: item?.metadata?.unitType,
    isDeductable: item?.metadata?.isDeductable,
    maxQuantity: (item as any)?.item?.inventory?.quantity,
    appliedDiscounts,
    // discountOf?: string
    // shipmentDate?: string
    preset: "cart"
  }
}

  const mapSavedForLaterProps = (item: SkuListItem ): CartSavedProps => ({
    productImage: connectImage(
      item?.metadata?.product_image
    ) as WrapperImageProps,
    productCode: item.sku_code!,
    title: item?.sku?.name ?? "",
    path: item?.metadata?.slug,
    // oldPrice: item?.item?.prices![0].compare_at_amount_float,
    currentPrice: item?.sku?.prices![0].amount_float!,
    inStock: item.sku?.inventory?.available,
    expirationDate: item?.metadata?.expirationDate && isDateDueIn90Days(item?.metadata?.expirationDate) ? getFormatDate(item?.metadata?.expirationDate) : '',
    // TODO: cart - handle price per unit
    pricePerUnit: item?.sku?.prices![0].amount_float,
    // TODO: cart - handle price per unit of mesure
    unitType: item?.metadata?.unitType,
    isDeductable: item?.metadata?.isDeductable
    // stars?: number
    // reviewsNb?: number
    // promotions?: ProductProps[]
    // hideControls?: boolean
  })

  const manageDiscountCodeToDisplay = (productsInCart: CartProps[]) => {

    if(productsInCart.length === 0) {
      return []
    }
    const productsWithoutGift = cloneDeep(productsInCart).filter(product => !product.isFreeGift)
    const productsGift = cloneDeep(productsInCart).filter(product => product.isFreeGift)

    // Get the first item's discount code
  const discountCodes = productsWithoutGift[0].appliedDiscounts ?? []

  if (discountCodes && discountCodes.length > 0)
    discountCodes.forEach((discountCode) => {
      // Check if all items have the same discount code
      let allItemsHaveSameDiscount = productsWithoutGift.every((item) => {
        if (item.appliedDiscounts && item.appliedDiscounts.length > 0) {
          return item.appliedDiscounts.find((appliedDiscount) =>
              appliedDiscount === discountCode
          )
        }
        return false
      })

      // If all items have the same discount, remove it from all items
      if (allItemsHaveSameDiscount) {
        productsWithoutGift.forEach((item) => {
          if (item.appliedDiscounts && item.appliedDiscounts.length > 0) {
            item.appliedDiscounts = item.appliedDiscounts.filter(
              (appliedDiscount) => appliedDiscount !== discountCode
            )
          }
        })
      }
    })

    return [...productsWithoutGift, ...productsGift]
  }

  const mapCartProductsFactory =
    <T>(mapCartProduct: (item: LineItem_<Sku>) => T) =>
    (
      freeGiftLineItems: LineItem_<FreeGiftPromotion>[]

    ) => (items: LineItem_<Sku>[]): (T & FreeGiftData)[] =>
    manageDiscountCodeToDisplay(items.flatMap(splitAndMapCartProducts(mapCartProduct)(freeGiftLineItems)))


  const mapCartProducts = mapCartProductsFactory(mapCartProduct)

  const splitAndMapCartProducts =
    <T>(mapCartProducts: (item: LineItem_<Sku>) => T) =>
    (freeGiftLineItems: LineItem_<FreeGiftPromotion>[]) =>
    (skuLineItem: LineItem_<Sku>): (T & FreeGiftData)[] => {
      const freeGiftQuantity =
        getFreeGiftQuantity(freeGiftLineItems)(skuLineItem)

      const nonFreeGiftQuantity = skuLineItem.quantity! - freeGiftQuantity

      const productData = mapCartProducts(skuLineItem)

      return [
        {
          quantity: freeGiftQuantity,
          splittedQuantity: nonFreeGiftQuantity,
          isFreeGift: true,
          ...productData
        },
        {
          quantity: nonFreeGiftQuantity,
          splittedQuantity: freeGiftQuantity,
          isFreeGift: false,
          ...productData
        }
      ].filter(({ quantity }) => quantity > 0)
    }

  const mapSavedForLater = (productList: SkuListItem[]): SavedForLaterProps => ( {
    productList: productList.map(mapSavedForLaterProps),
    showAtMax: 8,
    hideText: false
  })

  const mapNotificationAlert = (productList: LineItem_<Sku>[]): NotificationAlertProps => {
    const message = productList.map(product => (`· **${product.name}** è aumentato da ${formatMoney(product?.unit_amount_float!)} a ${formatMoney(product?.item.prices[0].amount_float)}.<br/>`))
    
    return { 
      id: Math.random(),
      type: "warning",
      isWithRepresentativeIcon: true,
      notificationAlertTitle:
        "Novità nel tuo carrello dall’ultima volta che l’hai visto",
      notificationAlertText: message.join(" "),
      isPopUp: true,
    }
  } 

  return {
    mapBannersSection,
    mapWhereToStart,
    mapAdvantages,
    mapBuyAgain,
    mapSavedForLater,
    mapCartProducts,
    mapCartProductsFactory,
    mapCartProduct,
    mapNotificationAlert
  }
}
